<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />

        <h2 class="brand-text text-primary ml-1">
          Asorefie
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Register V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title class="mb-1">
            Registration starts here 🚀
          </b-card-title>
          <b-card-text class="mb-2">
            Makes Church management simple and easy!
          </b-card-text>

          <!-- form -->
          <validation-observer
            ref="registerForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-register-form mt-2"
              @submit.prevent="register"
            >
              <b-row>
                <b-col cols="8">
                  <!-- fullname -->
                  <b-form-group
                    label="Fullname"
                    label-for="register-fullname"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Fullname"
                      vid="fullname"
                      rules="required"
                    >
                      <b-form-input
                        id="register-fullname"
                        v-model="form.fullname"
                        name="register-fullname"
                        :state="errors.length > 0 ? false:null"
                        placeholder="John Doe"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="4">
                  <!-- username -->
                  <b-form-group
                    label="Username"
                    label-for="register-username"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Username"
                      vid="username"
                      rules="required"
                    >
                      <b-form-input
                        id="register-username"
                        v-model="form.username"
                        name="register-username"
                        :state="errors.length > 0 ? false:null"
                        placeholder="joe"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="6">
                  <!-- email -->
                  <b-form-group
                    label="Email"
                    label-for="register-email"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Email"
                      vid="email"
                      rules="required|email"
                    >
                      <b-form-input
                        id="register-email"
                        v-model="form.email"
                        name="register-email"
                        :state="errors.length > 0 ? false:null"
                        placeholder="john@example.com"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <!-- password -->
                  <b-form-group
                    label-for="register-password"
                    label="Password"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Password"
                      vid="password"
                      rules="required"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid':null"
                      >
                        <b-form-input
                          id="register-password"
                          v-model="form.password"
                          class="form-control-merge"
                          :type="passwordFieldType"
                          :state="errors.length > 0 ? false:null"
                          name="register-password"
                          placeholder="············"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            :icon="passwordToggleIcon"
                            class="cursor-pointer"
                            @click="togglePasswordVisibility"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <!-- divider -->
            <div class="divider my-2">
              <div class="divider-text">
                Church
              </div>
            </div>

            <!-- name -->
            <b-form-group
                label="Name"
                label-for="register-name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Name"
                  vid="name"
                  rules="required"
                >
                  <b-form-input
                    id="register-name"
                    v-model="form.name"
                    name="register-name"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Sample Church"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
            </b-form-group>

            <b-row>
                <b-col cols="6">
                  <!-- type -->
                  <b-form-group
                    label="Type"
                    label-for="register-type"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Type"
                      vid="type"
                      rules="required"
                    >
                      <v-select
                        v-model="form.type"
                        :options="typeOptions"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <!-- region -->
                  <b-form-group
                    label="Region"
                    label-for="register-region"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Region"
                      vid="region"
                      rules="required"
                    >
                      <v-select
                        v-model="form.region"
                        :options="regionOptions"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
            </b-row>

            <b-row>
              <b-col sm="12" md="6" cols="6">
                <b-row>
                  <b-col sm="5">
                    <b-form-group
                    label="Code"
                    label-for="register-country"
                  >
                      <vue-country-code
                        class=" is-size-5"
                        @onSelect="onSelect"
                        v-model="form.country"
                        :enabledCountryCode="true"
                        :preferredCountries="[ 'us', 'gb', 'gh','ng','ke']"
                        >
                      </vue-country-code>
                    </b-form-group>
                  </b-col>
                  <b-col sm="7">
                    <b-form-group
                      label="Contact"
                      label-for="register-contact"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Contact"
                        vid="contact"
                        rules="required"
                      >
                        <b-form-input
                          id="register-contact"
                          v-model="form.contact1"
                          name="register-contact"
                          :state="errors.length > 0 ? false:null"
                          placeholder="XXXXXXXXX"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <b-col sm="12" md="6" cols="6">
                <!-- email -->
                <!-- <b-form-group
                  label="Email"
                  label-for="register-cemail"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    vid="cemail"
                    rules="required|email"
                  >
                    <b-form-input
                      id="register-cemail"
                      v-model="form.cemail"
                      name="register-cemail"
                      :state="errors.length > 0 ? false:null"
                      placeholder="john@example.com"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group> -->
                <!-- District -->
                <b-form-group
                  label="District"
                  label-for="register-district"
                >
                    <b-form-input
                      id="register-district"
                      v-model="form.district"
                      name="register-district"
                      placeholder="District "
                    />
                </b-form-group>
              </b-col>
            </b-row>

            
                <!-- Address -->
                <b-form-group
                  label="Address"
                  label-for="register-address"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Address"
                    vid="address"
                    rules="required"
                  >
                    <b-form-input
                      id="register-address"
                      v-model="form.addressName"
                      name="register-address"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Address "
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
            

                <b-form-group>
                  <b-form-checkbox
                    id="register-privacy-policy"
                    v-model="form.status"
                    name="checkbox-1"
                  >
                    I agree to
                    <b-link>privacy policy & terms</b-link>
                  </b-form-checkbox>
                </b-form-group>

              <b-button
                variant="primary"
                block
                type="submit"
                :disabled="invalid"
              >
                Sign up
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <span>Already have an account?</span>
            <b-link :to="{name:'auth-login'}">
              <span>&nbsp;Sign in instead</span>
            </b-link>
          </p>

          <!-- divider -->
          <div v-if="false" class="divider my-2">
            <div class="divider-text">
              or
            </div>
          </div>

          <div v-if="false" class="auth-footer-btn d-flex justify-content-center">
            <b-button
              variant="facebook"
              href="javascript:void(0)"
            >
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button
              variant="twitter"
              href="javascript:void(0)"
            >
              <feather-icon icon="TwitterIcon" />
            </b-button>
            <b-button
              variant="google"
              href="javascript:void(0)"
            >
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-button
              variant="github"
              href="javascript:void(0)"
            >
              <feather-icon icon="GithubIcon" />
            </b-button>
          </div>
        </b-col>
      </b-col>
    <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BButton, BForm, BFormCheckbox, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BImg, BCardTitle, BCardText,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import useJwt from '@/auth/jwt/useJwt'
  import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    VuexyLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    vSelect,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      form: {
        status: '',
        fullname: '',
        username: '',
        email: '',
        password: '',
        name: '',
        cemail: '',
        type: '',
        region: 'Greater Accra',
        district: '',
        country: '',
        contact1: '',
        country1: '',
        contact2: '',
        addressName: '',
      },
      sideImg: require('@/assets/images/pages/church.png'),
      // validation
      required,
      email,
      typeOptions: [ 'Catholic', 'Methodist', 'Anglican', 'Presbyterian', 'Methodist/Presby', 'Lutherans', 'Seventh-Day Adventist', 'Pentecostal', 'Baptist', 'Evangelical Charismatics', 'Latter-day Saint', 'Other' ],
      regionOptions: ['Ashanti','Brong Ahafo','Central','Eastern','Greater Accra','Northern','Upper East','Upper West','Volta','Western','Savannah','Bono East','Oti','Ahafo','Western North','North East'],
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    register() {
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          useJwt.register(this.form)
            .then(response => {
              useJwt.setToken(response.data.accessToken)
              useJwt.setRefreshToken(response.data.refreshToken)
              localStorage.setItem('userData', JSON.stringify(response.data.userData))
              this.$ability.update(response.data.userData.ability)
              this.$router.push('/')
            })
            .catch(error => {
              // this.$refs.registerForm.setErrors(error.response.data.error)

              this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Oops, Failed!`,
                      text: error.response.data,
                      icon: 'AlertTriangleIcon',
                      variant: error.response.data.includes("already exists") ? 'warning' : 'danger',
                    },
                  })
            })
        }
      })
    },
    onSelect({name, iso2, dialCode}) {
          this.form.country = "+"+dialCode
          console.log(name, iso2, dialCode);
      },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.vue-country-select  {
   width: 100%
}

.vue-country-select > .dropdown {
  padding: 0.6em !important;
}
</style>
