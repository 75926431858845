var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('vuexy-logo'),_c('h2',{staticClass:"brand-text text-primary ml-1"},[_vm._v(" Asorefie ")])],1),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",attrs:{"lg":"8"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5"},[_c('b-img',{attrs:{"fluid":"","src":_vm.imgUrl,"alt":"Register V2"}})],1)]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"mb-1"},[_vm._v(" Registration starts here 🚀 ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" Makes Church management simple and easy! ")]),_c('validation-observer',{ref:"registerForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-register-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.register($event)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"8"}},[_c('b-form-group',{attrs:{"label":"Fullname","label-for":"register-fullname"}},[_c('validation-provider',{attrs:{"name":"Fullname","vid":"fullname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-fullname","name":"register-fullname","state":errors.length > 0 ? false:null,"placeholder":"John Doe"},model:{value:(_vm.form.fullname),callback:function ($$v) {_vm.$set(_vm.form, "fullname", $$v)},expression:"form.fullname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Username","label-for":"register-username"}},[_c('validation-provider',{attrs:{"name":"Username","vid":"username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-username","name":"register-username","state":errors.length > 0 ? false:null,"placeholder":"joe"},model:{value:(_vm.form.username),callback:function ($$v) {_vm.$set(_vm.form, "username", $$v)},expression:"form.username"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"register-email"}},[_c('validation-provider',{attrs:{"name":"Email","vid":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-email","name":"register-email","state":errors.length > 0 ? false:null,"placeholder":"john@example.com"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label-for":"register-password","label":"Password"}},[_c('validation-provider',{attrs:{"name":"Password","vid":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"register-password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false:null,"name":"register-password","placeholder":"············"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('div',{staticClass:"divider my-2"},[_c('div',{staticClass:"divider-text"},[_vm._v(" Church ")])]),_c('b-form-group',{attrs:{"label":"Name","label-for":"register-name"}},[_c('validation-provider',{attrs:{"name":"Name","vid":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-name","name":"register-name","state":errors.length > 0 ? false:null,"placeholder":"Sample Church"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Type","label-for":"register-type"}},[_c('validation-provider',{attrs:{"name":"Type","vid":"type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.typeOptions,"state":errors.length > 0 ? false:null},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Region","label-for":"register-region"}},[_c('validation-provider',{attrs:{"name":"Region","vid":"region","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.regionOptions,"state":errors.length > 0 ? false:null},model:{value:(_vm.form.region),callback:function ($$v) {_vm.$set(_vm.form, "region", $$v)},expression:"form.region"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"6","cols":"6"}},[_c('b-row',[_c('b-col',{attrs:{"sm":"5"}},[_c('b-form-group',{attrs:{"label":"Code","label-for":"register-country"}},[_c('vue-country-code',{staticClass:" is-size-5",attrs:{"enabledCountryCode":true,"preferredCountries":[ 'us', 'gb', 'gh','ng','ke']},on:{"onSelect":_vm.onSelect},model:{value:(_vm.form.country),callback:function ($$v) {_vm.$set(_vm.form, "country", $$v)},expression:"form.country"}})],1)],1),_c('b-col',{attrs:{"sm":"7"}},[_c('b-form-group',{attrs:{"label":"Contact","label-for":"register-contact"}},[_c('validation-provider',{attrs:{"name":"Contact","vid":"contact","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-contact","name":"register-contact","state":errors.length > 0 ? false:null,"placeholder":"XXXXXXXXX"},model:{value:(_vm.form.contact1),callback:function ($$v) {_vm.$set(_vm.form, "contact1", $$v)},expression:"form.contact1"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)],1),_c('b-col',{attrs:{"sm":"12","md":"6","cols":"6"}},[_c('b-form-group',{attrs:{"label":"District","label-for":"register-district"}},[_c('b-form-input',{attrs:{"id":"register-district","name":"register-district","placeholder":"District "},model:{value:(_vm.form.district),callback:function ($$v) {_vm.$set(_vm.form, "district", $$v)},expression:"form.district"}})],1)],1)],1),_c('b-form-group',{attrs:{"label":"Address","label-for":"register-address"}},[_c('validation-provider',{attrs:{"name":"Address","vid":"address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-address","name":"register-address","state":errors.length > 0 ? false:null,"placeholder":"Address "},model:{value:(_vm.form.addressName),callback:function ($$v) {_vm.$set(_vm.form, "addressName", $$v)},expression:"form.addressName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('b-form-checkbox',{attrs:{"id":"register-privacy-policy","name":"checkbox-1"},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}},[_vm._v(" I agree to "),_c('b-link',[_vm._v("privacy policy & terms")])],1)],1),_c('b-button',{attrs:{"variant":"primary","block":"","type":"submit","disabled":invalid}},[_vm._v(" Sign up ")])],1)]}}])}),_c('p',{staticClass:"text-center mt-2"},[_c('span',[_vm._v("Already have an account?")]),_c('b-link',{attrs:{"to":{name:'auth-login'}}},[_c('span',[_vm._v(" Sign in instead")])])],1),(false)?_c('div',{staticClass:"divider my-2"},[_c('div',{staticClass:"divider-text"},[_vm._v(" or ")])]):_vm._e(),(false)?_c('div',{staticClass:"auth-footer-btn d-flex justify-content-center"},[_c('b-button',{attrs:{"variant":"facebook","href":"javascript:void(0)"}},[_c('feather-icon',{attrs:{"icon":"FacebookIcon"}})],1),_c('b-button',{attrs:{"variant":"twitter","href":"javascript:void(0)"}},[_c('feather-icon',{attrs:{"icon":"TwitterIcon"}})],1),_c('b-button',{attrs:{"variant":"google","href":"javascript:void(0)"}},[_c('feather-icon',{attrs:{"icon":"MailIcon"}})],1),_c('b-button',{attrs:{"variant":"github","href":"javascript:void(0)"}},[_c('feather-icon',{attrs:{"icon":"GithubIcon"}})],1)],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }